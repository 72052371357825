import * as ActionTypes from '../../action.types';
import {
    IShareableList,
    ShareableListAddItemsInput,
    ShareableListItem,
    ShareableListRemoveItemsInput
} from '@interfaces/shareable-lists';

export const setSearchState = (searchState: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_PAGE_SET_SEARCH_STATE,
        payload: {
            searchState
        }
    };
};

export const setDetailedPageList = (shareableList: IShareableList) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_LIST,
        payload: {
            shareableList
        }
    };
};

export const appendItemToSelectedItems = (itemType: ShareableListItem, itemId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_APPEND_ITEM,
        payload: {
            itemType,
            itemId
        }
    };
};

export const removedItemFromSelectedItems = (itemType: ShareableListItem, itemId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_REMOVE_ITEM,
        payload: {
            itemType,
            itemId
        }
    };
};

export const appendItemToCheckedItems = (itemType: ShareableListItem, itemId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_APPEND_ITEM,
        payload: {
            itemType,
            itemId
        }
    };
};

export const removeItemFromCheckedItems = (itemType: ShareableListItem, itemId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_REMOVE_ITEM,
        payload: {
            itemType,
            itemId
        }
    };
};

export const addItemsToList = (
    items: ShareableListAddItemsInput,
    listName: string,
    listId?: string
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_ASYNC,
        payload: {
            items,
            listId,
            listName
        }
    };
};

export const removeItemsFromList = (
    items: ShareableListRemoveItemsInput,
    listName: string,
    listId: string
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_ASYNC,
        payload: {
            items,
            listId,
            listName
        }
    };
};

export const setDetailedPageEditMode = (mode: boolean) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_EDIT_MODE,
        payload: {
            mode
        }
    };
};
