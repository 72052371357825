import React from 'react';
import parser from 'html-react-parser';
import { Card, H4 } from '@blueprintjs/core';
import OpeningTimesBlock from './opening-times-block';
import { IOpeningTimes } from '../../../../services/partners-service/types';

interface IAboutTab {
    description?: string;
    openingTimes?: IOpeningTimes[];
}

const AboutTab: React.FunctionComponent<IAboutTab> = ({ description, openingTimes }) => {
    return (
        <div className={'grid gap-2 w-full'}>
            {description && (
                <Card className={'p-2'}>
                    <H4> Description </H4>
                    <p> {parser(description)} </p>
                </Card>
            )}
            {openingTimes && <OpeningTimesBlock openingTimes={openingTimes} />}
        </div>
    );
};

export default AboutTab;
