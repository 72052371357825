import React, { useEffect, useState } from 'react';
import { IOffering } from '../../../../modules/products-display/types';
import { InputGroup } from '@blueprintjs/core';
import MiniProductsListWithRenderUI from './mini-products-list';
import { IContact } from '@interfaces/partner';

const FAKE_PRODUCTS: IOffering[] = [
    {
        _id: '1',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: true,
        discount: {
            new_price: 74.99
        },
        price: {
            price: 92.4,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 6.6,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '2',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 102.2,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 7.3,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '3',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: true,
        discount: {
            percentage: 30
        },
        price: {
            price: 75.6,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.4,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 68.6,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 4.9,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '5',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 75.6,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.4,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '6',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 82.6,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.9,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '7',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 82.6,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.9,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '8',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 77,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.5,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '9',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 81.2,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.8,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '10',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 770,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 55,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '11',
        name: 'Half turkey in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1597331429/products/test/broiler-chicken-package.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 78.4,
            unit: 'kg'
        },
        ref_price: {
            price: 14,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 5.6,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    },
    {
        _id: '4',
        name: 'Turkey breast in vacuum',
        ian: '1234567891011',
        description:
            'Half turkey in vacuum. The bird was born 15.04.2020. Turkey was fed with local oat and Finnish feed mixture. ' +
            'They lived outside since 6 weeks and were stored in cages much bigger than required so they could have low stress life. ' +
            'Small scale produced and slaughtered 29.10.2020 (Not inspected). ' +
            'Packed and frozen: 30.10.2020. Salmonella tested, ',
        tags: ['turkey', 'Finnish'],
        image:
            'https://res.cloudinary.com/coresine/image/upload/v1605699713/businesses/antti_farm/turkey-breast_b21fpe.jpg',
        categories: ['Farm', 'Meat store'],
        is_discounted: false,
        price: {
            price: 92.5,
            unit: 'kg'
        },
        ref_price: {
            price: 25,
            unit: 'kg'
        },
        details: {
            weight: {
                weight: 3.7,
                unit: 'kg'
            }
        },
        business: 'asdasd'
    }
];

interface IProductsTab {
    contact?: IContact;
}

const ProductsTab: React.FunctionComponent<IProductsTab> = ({ contact }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [error] = useState();
    const [isFetching] = useState<boolean>(false);
    const [randomProductName, setRandomProductName] = useState<string>('It is empty =(');
    const [products] = useState<IOffering[]>([]);

    useEffect(() => {
        // Replaced products with fake data
        if (FAKE_PRODUCTS.length > 0) {
            setRandomProductName(
                FAKE_PRODUCTS[Math.floor(Math.random() * FAKE_PRODUCTS.length)].name
            );
        }
    }, [products]);

    const onChangeHandler = (searchText: any) => {
        setSearchQuery(searchText.currentTarget.value);
    };

    return (
        <section className={'flex flex-col items-start w-full'}>
            <InputGroup
                leftIcon={'search'}
                type={'search'}
                value={searchQuery}
                placeholder={`Eg. ${randomProductName}`}
                inputMode={'search'}
                large={false}
                onChange={onChangeHandler}
                className={'mb-4 w-full self-start'}
            />
            <MiniProductsListWithRenderUI
                data={FAKE_PRODUCTS}
                contact={contact}
                searchQuery={searchQuery}
                error={error}
                isFetching={isFetching}
            />
        </section>
    );
};

export default ProductsTab;
