import React from 'react';
import { IOffering } from '../../../../modules/products-display/types';
import MiniProductCard from './mini-product-card';
import withRenderUI from '../../../../hoc/render-ui-hoc';
import { css } from '@emotion/core';
import { IContact } from '@interfaces/partner';
// import { useTheme } from 'emotion-theming';
// import Theme from '../../../../utils/theming/theme-type';
// import { ProductCard } from '../../product-card';

interface IMiniProductsList {
    data: IOffering[];
    contact?: IContact;
    searchQuery: string;
    error: any;
    isFetching: boolean;
}

const MiniProductsList: React.FunctionComponent<IMiniProductsList> = ({
    data,
    searchQuery,
    contact
}) => {
    const filteringHandler = (givenData: IOffering[]) => {
        return givenData.filter((product: IOffering) =>
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <>
            <div
                css={css`
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
                    grid-auto-rows: 9rem;
                    padding: 0.1rem;
                    gap: 0.5rem;
                    width: 100%;
                    min-height: 40vh;
                    height: 100%;
                    overflow-y: scroll;
                    padding-right: 1rem;
                `}>
                {filteringHandler(data).map((product: IOffering) => (
                    <MiniProductCard {...product} contact={contact} />
                ))}
            </div>
        </>
    );
};

const MiniProductsListWithRenderUI = withRenderUI(MiniProductsList);

export default MiniProductsListWithRenderUI;
