import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';

const CategoryBlock: React.FunctionComponent = () => {
    const theme: Theme = useTheme();

    return (
        <span
            css={css`
                color: ${theme.colors.tertiary};
                font-size: ${theme.fontSize.secondary};
            `}></span>
    );
};

export default CategoryBlock;
