import React from 'react';
// import { IAddress } from '../../../../modules/products-display/types';
import { css } from '@emotion/core';
import { Text } from '@blueprintjs/core';
import { IAddress } from '../../../../services/partners-service/types';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';

interface IAddressBlock {
    address: IAddress;
    mode: 'detailed' | 'basic';
    width: string;
    className?: string;
}

const AddressBlock: React.FunctionComponent<IAddressBlock> = ({ address, width, className }) => {
    const theme: Theme = useTheme();

    const addressDivider = (): string[] => {
        return address.address.split(',');
    };

    const splitAddress = addressDivider();

    const onClickHandler = (e) => {
        e.preventDefault();
    };

    return (
        <section
            onClick={onClickHandler}
            className={className}
            css={css`
                display: flex;
                flex-direction: column;
                width: ${width};
            `}>
            <Text
                ellipsize={true}
                css={css`
                    font-size: ${theme.fontSize.default};
                `}>
                {splitAddress[0]}
            </Text>
            <span
                css={css`
                    font-size: ${theme.fontSize.secondary};
                    color: gray;
                `}>
                {splitAddress[1]}
            </span>
        </section>
    );
};

export default AddressBlock;
