import React from 'react';
import { Text } from '@blueprintjs/core';
import { css } from '@emotion/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';

interface ITitleBlock {
    title: string;
    width?: string;
    isOverlay?: boolean;
    mode: 'basic' | 'detailed';
}

const TitleBlock: React.FunctionComponent<ITitleBlock> = ({ title, width = '100%', mode }) => {
    const theme: Theme = useTheme();

    return (
        <h4
            className={''}
            css={css`
                color: ${theme.colors.secondary};
                font-size: ${mode === 'basic' && theme.fontSize.default}
                    ${mode === 'detailed' && theme.fontSize.header};
                font-weight: bold;
                margin: 0;
                margin-bottom: 0.5rem;
                overflow-wrap: break-word;
                width: ${mode === 'basic' && '100%'} ${mode === 'detailed' && '80%'};
                ${theme.layout.mq[1]} {
                    width: ${width};
                }
            `}>
            <Text ellipsize={true}>{title}</Text>
        </h4>
    );
};

export default TitleBlock;
