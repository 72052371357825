import { GraphQLResult } from '@aws-amplify/api';
import * as Queries from './queries';
import * as Types from './types.d';
import { PartnerDto } from './dto';
import * as CommonGraphql from './graphql/common';

export class Client {
    static mapListPartnersQuery(
        listPartnersQuery: GraphQLResult<Types.ListPartnersQuery>
    ): Types.IPartner[] {
        return (
            listPartnersQuery.data?.listPartners?.map((partner: any) => new PartnerDto(partner)) ||
            []
        );
    }

    static mapGetPartnerByIdQuery(
        getPartnerByIdQuery: GraphQLResult<Types.GetPartnerByIdQuery> = {}
    ): Types.IPartner | {} {
        console.log('here');
        return getPartnerByIdQuery.data?.getPartnerById?.id
            ? getPartnerByIdQuery.data?.getPartnerById
            : {};
    }
}

export { Queries };
export { Types };
export { CommonGraphql };
