import { Card, Icon } from '@blueprintjs/core';
import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { IContact } from '@interfaces/partner';

interface IContactsTab extends IContact {
    iconSize: number;
    address?: string;
    partnerName?: string;
    sectionClassnames?: string;
}

const ContactsBlock: React.FunctionComponent<IContactsTab> = ({
    website,
    name,
    partnerName,
    phone_number,
    iconSize,
    address,
    sectionClassnames
}) => {
    return (
        <section className={sectionClassnames}>
            <Card className={'p-2'}>
                <span className={'flex items-center font-bold mb-4'}>
                    <Icon
                        icon={IconNames.SHOP}
                        color={'gray'}
                        iconSize={iconSize}
                        className={'mr-2'}
                    />
                    {partnerName}
                </span>
                <span className={'flex items-center mb-4'}>
                    <Icon
                        icon={IconNames.PERSON}
                        color={'gray'}
                        iconSize={iconSize}
                        className={'mr-2'}
                    />
                    {name}
                </span>
                <span className={'flex items-center mb-4'}>
                    <Icon
                        icon={IconNames.HOME}
                        color={'gray'}
                        iconSize={iconSize}
                        className={'mr-2'}
                    />
                    {address}
                </span>
                <span className={'flex items-center mb-4'}>
                    <Icon
                        icon={IconNames.PHONE}
                        color={'gray'}
                        iconSize={iconSize}
                        className={'mr-2'}
                    />
                    {phone_number}
                </span>
                <span className={'flex items-center'}>
                    <Icon
                        icon={IconNames.GLOBE_NETWORK}
                        color={'gray'}
                        iconSize={iconSize}
                        className={'mr-2'}
                    />
                    <a
                        href={website}
                        className={'text-blue-500 hover:text-blue-800 hover:underline'}>
                        {' '}
                        {website}{' '}
                    </a>
                </span>
            </Card>
        </section>
    );
};

export default ContactsBlock;
