import React from 'react';
import { css } from '@emotion/core';
import {
    IShareableListItemPartner,
    IShareableListItemPartnerSub,
    ShareableListRemoveItemsInput
} from '@interfaces/shareable-lists';
import { Alert, ButtonGroup, Card, EditableText, Intent, Menu, Popover } from '@blueprintjs/core';
import { DividingCircle, TrulyLocalBadge } from '../../../../../../../components/common';
import TitleBlock from '../../../../../../../components/cards/common/components/title-block';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../../../../utils/theming/theme-type';
import {
    AddressBlock,
    DistanceBlock
} from '../../../../../../../components/cards/partner-card/components';
import useMedia from 'use-media';
import { Button } from '@components/ui';

interface IProps {
    partner: IShareableListItemPartnerSub;
    item: IShareableListItemPartner;
    isItemSelected?: boolean;
    toggleItemSelection?: (itemId: string, selection: boolean) => void;
    isItemChecked?: boolean;
    toggleItemCheck?: (itemId: string, check: boolean) => void;
    noteSaveAsync?: (itemId: string, note: string) => void;
    removeItemsFromList?: (input: ShareableListRemoveItemsInput) => void;
}

const SLPartnerCard: React.FunctionComponent<IProps> = ({
    partner,
    item,
    isItemSelected,
    toggleItemSelection,
    isItemChecked,
    toggleItemCheck,
    noteSaveAsync,
    removeItemsFromList
}) => {
    const theme: Theme = useTheme();
    const isMobile = useMedia({ maxWidth: 576 });

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [areButtonsVisible, setAreButtonVisible] = React.useState<boolean>(false);
    const [isToBeDeleted, setIsToBeDeleted] = React.useState<boolean>(false);
    const [note, setNote] = React.useState<string>(item.note ?? '');

    const onMouseEnterHandler = (): void => {
        setAreButtonVisible(true);
    };

    const onMouseLeaveHandler = (): void => {
        setAreButtonVisible(false);
    };

    const onAlertCancelHandler = (): void => {
        setIsToBeDeleted(false);
    };

    const onNoteSaveHandler = (): void => {
        noteSaveAsync(item._id, note);
        setIsEditing(true);
    };

    const onDeleteButtonPressHandler = (event): void => {
        event.stopPropagation();
        setIsToBeDeleted(true);
    };

    const onDeleteConfirmHandler = (): void => {
        if (removeItemsFromList) {
            removeItemsFromList({
                partners: {
                    ids: [item._id]
                }
            });
        }
    };

    const onSelectButtonClickHandler = (): void => {
        if (toggleItemSelection) {
            if (typeof isItemSelected === 'undefined') {
                return;
            }

            isItemSelected
                ? toggleItemSelection(item._id, false)
                : toggleItemSelection(item._id, true);
        }
    };

    const onCheckButtonClickHandler = (): void => {
        if (toggleItemCheck) {
            if (typeof isItemChecked === 'undefined') {
                return;
            }

            isItemChecked ? toggleItemCheck(item._id, false) : toggleItemCheck(item._id, true);
        }
    };

    const toggleEditMode = (): void => {
        if (isEditing) {
            noteSaveAsync(item._id, note);
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    };

    const moreButtonMenu = (
        <Menu>
            <Menu.Item
                text={isEditing ? 'Save' : 'Edit'}
                icon={isEditing ? 'floppy-disk' : 'edit'}
                intent={Intent.PRIMARY}
                onClick={toggleEditMode}
            />
            <Menu.Item
                icon={'trash'}
                text={'Delete'}
                onClick={onDeleteButtonPressHandler}
                intent={Intent.DANGER}
            />
        </Menu>
    );

    return (
        <section
            onMouseLeave={onMouseLeaveHandler}
            onMouseEnter={onMouseEnterHandler}
            css={css`
                width: 100%;
                min-width: 320px;
                ${isItemChecked && `opacity: 0.3`};
            `}>
            <Card
                onClick={onSelectButtonClickHandler}
                interactive={isItemSelected}
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    height: auto;
                    padding: 0.75rem;
                    ${isItemSelected &&
                    `box-shadow: 0 0 1px 2px ${theme.colors.primary} !important;`}
                    ${theme.layout.mq[0]} {
                        flex-direction: row;
                    }
                `}>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    `}>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            // margin: 0 1rem;
                            flex: 1;
                        `}>
                        <div
                            css={css`
                                display: flex;
                                align-items: baseline;
                                justify-content: flex-start;
                                margin-bottom: 0.5rem;
                            `}>
                            <TitleBlock title={partner.name} mode={'basic'} width={'auto'} />
                            <DividingCircle />
                            <TrulyLocalBadge width={'auto'} />
                        </div>
                        <div
                            css={css`
                                display: flex;
                                align-items: baseline;
                            `}>
                            <AddressBlock address={partner.address} mode={'basic'} width={'auto'} />
                            <DividingCircle />
                            {partner.address.location.coordinates ? (
                                <DistanceBlock
                                    startCoordinates={partner.address.location.coordinates}
                                    address={partner.address.address}
                                    endCoordinates={{
                                        latitude: partner.address.location.coordinates[0],
                                        longitude: partner.address.location.coordinates[1]
                                    }}
                                />
                            ) : (
                                <span />
                            )}
                        </div>
                        <EditableText
                            type={'text'}
                            confirmOnEnterKey={true}
                            placeholder={'Your note...'}
                            defaultValue={note}
                            value={note}
                            isEditing={isEditing}
                            onChange={setNote}
                            onConfirm={onNoteSaveHandler}
                            multiline={true}
                            css={css`
                                > span,
                                > input {
                                    font-size: ${theme.fontSize.default};
                                }
                                margin: 0.5rem 0;
                                max-width: 18rem;
                            `}
                        />
                    </div>
                </div>
                <ButtonGroup
                    vertical={!isMobile}
                    minimal={true}
                    fill={true}
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        transition: 0.2s ease-in-out;
                        opacity: 1;
                        ${theme.layout.mq[0]} {
                            opacity: ${areButtonsVisible ? 1 : isItemSelected ? 1 : 0};
                        }
                    `}>
                    <Button
                        icon={isItemChecked ? 'cross' : 'tick'}
                        minimal={true}
                        large={false}
                        text={isMobile && (isItemChecked ? 'Uncheck' : 'Check')}
                        onClick={onCheckButtonClickHandler}
                        intent={isItemChecked ? null : Intent.PRIMARY}
                    />
                    <Button
                        icon={isItemSelected ? 'full-circle' : 'circle'}
                        intent={Intent.PRIMARY}
                        text={isMobile && (isItemSelected ? 'Unselect' : 'Select')}
                        onClick={onSelectButtonClickHandler}
                    />
                    <Popover content={moreButtonMenu} disabled={isEditing}>
                        <Button
                            icon={isEditing ? 'floppy-disk' : 'more'}
                            onClick={isEditing ? onNoteSaveHandler : null}
                            text={isMobile && (isEditing ? 'Save' : 'More')}
                            intent={isEditing ? Intent.PRIMARY : null}
                            minimal={true}
                            large={false}
                        />
                    </Popover>
                </ButtonGroup>
                <Alert
                    isOpen={isToBeDeleted}
                    cancelButtonText={'Do not delete'}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true}
                    confirmButtonText={'Delete'}
                    onCancel={onAlertCancelHandler}
                    intent={Intent.DANGER}
                    onConfirm={onDeleteConfirmHandler}>
                    <p>
                        Are you sure you want to delete <strong> {partner.name} </strong> from this
                        list?
                    </p>
                </Alert>
            </Card>
        </section>
    );
};

export default SLPartnerCard;
