export const SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_ASYNC =
    'SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_ASYNC';
export const SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_SUCCESS =
    'SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_SUCCESS';
export const SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_FAILED =
    'SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_FAILED';

export const SHAREABLE_LISTS_MODULE_FETCH_BY_ID_ASYNC = 'SHAREABLE_LISTS_MODULE_FETCH_BY_ID_ASYNC';
export const SHAREABLE_LISTS_MODULE_FETCH_BY_ID_SUCCESS =
    'SHAREABLE_LISTS_MODULE_FETCH_BY_ID_SUCCESS';
export const SHAREABLE_LISTS_MODULE_FETCH_BY_ID_FAILED =
    'SHAREABLE_LISTS_MODULE_FETCH_BY_ID_FAILED';

export const SHAREABLE_LISTS_MODULE_CREATE_LIST_ASYNC = 'SHAREABLE_LISTS_MODULE_CREATE_LIST_ASYNC';
export const SHAREABLE_LISTS_MODULE_CREATE_LIST_SUCCESS =
    'SHAREABLE_LISTS_MODULE_CREATE_LIST_SUCCESS';
export const SHAREABLE_LISTS_MODULE_CREATE_LIST_FAILED =
    'SHAREABLE_LISTS_MODULE_CREATE_LIST_FAILED';

export const SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_ASYNC =
    'SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_ASYNC';
export const SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_SUCCESS =
    'SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_SUCCESS';
export const SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_FAILED =
    'SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_FAILED';

export const SHAREABLE_LISTS_MODULE_DELETE_BY_ID_ASYNC =
    'SHAREABLE_LISTS_MODULE_DELETE_BY_ID_ASYNC';
export const SHAREABLE_LISTS_MODULE_DELETE_BY_ID_SUCCESS =
    'SHAREABLE_LISTS_MODULE_DELETE_BY_ID_SUCCESS';
export const SHAREABLE_LISTS_MODULE_DELETE_BY_ID_FAILED =
    'SHAREABLE_LISTS_MODULE_DELETE_BY_ID_FAILED';

// PAGES

export const SHAREABLE_LISTS_MODULE_PAGE_SET_SEARCH_STATE =
    'SHAREABLE_LISTS_MODULE_PAGE_SET_SEARCH_STATE';

/// UI

// General
export const SHAREABLE_LISTS_MODULE_PAGE_ADD_TO_LIST_ASYNC =
    'SHAREABLE_LISTS_MODULE_PAGE_ADD_TO_LIST_ASYNC';
export const SHAREABLE_LISTS_MODULE_PAGE_ADD_TO_LIST_SUCCESS =
    'SHAREABLE_LISTS_MODULE_PAGE_ADD_TO_LIST_SUCCESS';
export const SHAREABLE_LISTS_MODULE_PAGE_ADD_TO_LIST_FAILED =
    'SHAREABLE_LISTS_MODULE_PAGE_ADD_TO_LIST_FAILED';

// Detailed SL page
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_LIST =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_LIST';

export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_ALL_ITEMS =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_ALL_ITEMS';
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_APPEND_ITEM =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_APPEND_ITEM';
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_REMOVE_ITEM =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SELECT_REMOVE_ITEM';
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_DELETE_SELECTED_ITEMS =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_DELETE_SELECTED_ITEMS';

export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_APPEND_ITEM =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_APPEND_ITEM';
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_REMOVE_ITEM =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_CHECK_REMOVE_ITEM';

export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_ASYNC =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_ASYNC';
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_SUCCESS =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_SUCCESS';
export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_FAILED =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_FAILED';

export const SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_EDIT_MODE =
    'SHAREABLE_LISTS_MODULE_DETAILED_PAGE_SET_EDIT_MODE';

// Common
export const SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_ASYNC =
    'SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_ASYNC';
export const SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_SUCCESS =
    'SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_SUCCESS';
export const SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_FAILED =
    'SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_FAILED';
