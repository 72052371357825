import { IAddress, IContact, IGeoLoc, IImage, IOpeningTimes, IPartner } from './types';

export class PartnerDto {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _categories: string[];
    private readonly _tags: string[];
    private readonly _images: IImage[] | string[];
    private readonly _address: IAddress;
    private readonly _openingTimes: IOpeningTimes[];
    private readonly _description: string;
    private readonly _contact: IContact;
    private readonly _createdAt: string;
    private readonly _updatedAt: string;
    private readonly _geoloc: IGeoLoc;

    constructor(data: IPartner) {
        this._id = data['_id'];
        this._name = data['name'];
        this._categories = data['categories'];
        this._tags = data['tags'];
        this._images = data['images'];
        this._address = data['address'];
        this._openingTimes = data['openingTimes'];
        this._description = data['description'];
        this._contact = data['contact'];
        this._createdAt = data['createdAt'];
        this._updatedAt = data['updatedAt'];
        this._geoloc = data['_geoloc'];
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get categories(): string[] {
        return this._categories;
    }

    get tags(): string[] {
        return this._tags;
    }

    get images(): IImage[] | string[] {
        return this._images;
    }

    get address(): IAddress {
        return this._address;
    }

    get openingTimes(): IOpeningTimes[] {
        return this._openingTimes;
    }

    get description(): string {
        return this._description;
    }

    get contact(): IContact {
        return this._contact;
    }

    get createdAt(): string {
        return this._createdAt;
    }

    get updatedAt(): string {
        return this._updatedAt;
    }

    get geoloc(): IGeoLoc {
        return this._geoloc;
    }
}
