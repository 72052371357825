import gql from 'graphql-tag';

export const GetPartnerById = gql`
    query GetPartnerById($id: ID!) {
        getPartnerById(id: $id) {
            __typename
            id
            name
        }
    }
`;

export const ListPartners = gql`
    query ListPartners {
        listPartners {
            id
            name
        }
    }
`;
