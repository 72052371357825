import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Button, Dialog, Divider, InputGroup, Intent } from '@blueprintjs/core';
import ShareableListCreateForm from './shareable-list-create-form';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../../utils/theming/theme-type';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as AsyncOperations from '@stores/shareable-lists/operations';
import * as Types from '@interfaces/shareable-lists';
import { connect } from 'react-redux';
import useMedia from 'use-media';
import { Actions } from '../../../index';

interface IShareableListButtons {
    setShareableListsSearchState?: (searchState: string) => void;
    createList?: (input: Types.IShareableListCreateInput, ownerId: string) => void;
    isCreatingList?: boolean;
    userId: string;
}

const ShareableListButtons: React.FunctionComponent<IShareableListButtons> = (props) => {
    const theme: Theme = useTheme();
    const isMobile = useMedia({ maxWidth: 576 });

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const openDialogHandler = (): void => {
        setIsDialogOpen(true);
    };

    const closeDialogHandler = (): void => {
        setIsDialogOpen(false);
    };

    const setSearchStateHandler: any = (event) => {
        props.setShareableListsSearchState(event.currentTarget.value);
    };

    const createListHandler = (input: Types.IShareableListCreateInput) => {
        if (props.userId) {
            props.createList(input, props.userId);
        } else {
            console.error(new Error('No owner exists'));
        }
    };

    return (
        <div className={'flex flex-col items-start mb-2'}>
            <h2
                css={css`
                    font-size: ${theme.fontSize.header};
                    margin: 0;
                `}>
                Your lists
            </h2>
            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 0.5rem;
                `}>
                <InputGroup
                    type={'search'}
                    placeholder={'Search for a list'}
                    large={false}
                    onChange={setSearchStateHandler}
                    leftIcon={'search'}
                    css={css`
                        > input {
                            font-size: ${theme.fontSize.default} !important;
                        }
                        min-width: 8rem;
                        margin-right: 1rem;
                    `}
                />
                {props.userId ? (
                    <>
                        <Button
                            intent={Intent.PRIMARY}
                            icon={isMobile ? 'add' : null}
                            text={isMobile ? null : 'Create a new list'}
                            large={false}
                            minimal={isMobile}
                            onClick={openDialogHandler}
                        />
                        <Dialog
                            isOpen={isDialogOpen}
                            title={'Create a shareable list'}
                            isCloseButtonShown={true}
                            onClose={closeDialogHandler}
                            canOutsideClickClose={true}
                            usePortal={true}
                            canEscapeKeyClose={true}
                            className={'pb-0 bg-white'}>
                            <ShareableListCreateForm
                                closeCreateListDialogHandler={closeDialogHandler}
                                isCreating={props.isCreatingList}
                                createListHandler={createListHandler}
                            />
                        </Dialog>{' '}
                    </>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({
    shareableListsPageState
}: {
    shareableListsPageState: Types.IShareableListModuleState;
}) => {
    return {
        isCreatingList: shareableListsPageState?.flags?.creatingList?.inProgress ?? false
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        createList: (input: Types.IShareableListCreateInput, ownerId: string) => {
            return dispatch(AsyncOperations.createListAsync(input, ownerId));
        },
        setShareableListsSearchState: (searchState: string) =>
            dispatch(Actions.setSearchState(searchState))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareableListButtons);
