import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ShareableListCard } from '../../../../../components/cards/shareable-list';
import { AsyncOperations } from '../../../index';
import { css } from '@emotion/core';
import { NonIdealState } from '@blueprintjs/core';
import { OneToTwoColumnsGridWrapper } from '../../../../../components/common';
import * as Types from '@interfaces/shareable-lists';
import { selectLists } from '@stores/shareable-lists/selectors';

interface IProps {
    searchState: string;
    shareableLists: Types.IShareableList[];
    fetchMyShareableLists?: any;
    shareableListsHasBeenFetched?: boolean;
    setShareableListsSearchState: (searchState: string) => void;
    deleteListAsync: (listId: string, userId: string) => void;
    userId?: string;
}

const ShareableListsList: React.FunctionComponent<IProps> = ({
    shareableLists,
    searchState,
    ...props
}) => {
    const deleteList = (listId: string) => {
        if (props.userId) {
            props.deleteListAsync(listId, props.userId);
        }
    };

    return (
        <div
            css={css`
                margin-top: 0.5rem;
            `}>
            {shareableLists?.length ? (
                <OneToTwoColumnsGridWrapper
                    padding={''}
                    templateColumnsOnMQ0={'1fr 1fr'}
                    templateColumnsOnMQ2={'1fr 1fr 1fr 1fr'}
                    gap={'0.5rem'}>
                    {Object.keys(shareableLists).map((key: string) => {
                        console.log('key: ', key);
                        if (
                            shareableLists[key].name
                                .toLowerCase()
                                .includes(searchState.toLowerCase())
                        ) {
                            return (
                                <ShareableListCard
                                    shareableList={shareableLists[key]}
                                    key={key}
                                    deleteList={deleteList}
                                />
                            );
                        }
                    })}
                </OneToTwoColumnsGridWrapper>
            ) : (
                <NonIdealState
                    icon={'applications'}
                    title={'You have no lists here'}
                    css={css`
                        margin: 2rem 0;
                    `}
                    description={
                        'You have no lists on your account, but you can create one by clicking the button to create one'
                    }
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: {
    shareableListsPageState: Types.IShareableListModuleState;
    authState: any;
}) => {
    return {
        shareableLists: selectLists(state),
        searchState: state.shareableListsPageState.listsSearchState,
        shareableListsHasBeenFetched:
            state.shareableListsPageState?.flags?.fetchingListsByOwnerId?.inProgress || false,
        userId: state.authState?.cognitoUserPayload?.docId ?? undefined
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        deleteListAsync: (listId: string, userId: string) => {
            return dispatch(AsyncOperations.deleteListByIdAsync(userId, listId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareableListsList);
