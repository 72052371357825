import React from 'react';
import { css } from '@emotion/core';
import { Alert, Button, ButtonGroup, Card, Intent } from '@blueprintjs/core';
import { ProgressiveImage } from '../../../../../../../components/common';
import TitleBlock from '../../../../../../../components/cards/common/components/title-block';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../../../../utils/theming/theme-type';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

const SLOfferingCard: React.FunctionComponent<any> = ({
    name,
    image,
    offering_id,
    selectedItems,
    checkedItems,
    appendSelectOneItemHandler,
    removeSelectOneItemHandler,
    appendCheckedOneItemHandler,
    removeCheckedOneItemHandler,
    deleteOneItemHandler
}) => {
    const theme: Theme = useTheme();

    React.useEffect(() => {
        setIsSelected(!!selectedItems?.find(findCallbackFunction));
    }, [selectedItems]);

    React.useEffect(() => {
        setIsChecked(!!checkedItems?.find(findCheckedCallbackFunction));
    }, [checkedItems]);

    const [isSelected, setIsSelected] = React.useState<boolean>(false);
    const [isChecked, setIsChecked] = React.useState<boolean>(false);
    const [areButtonsVisible, setAreButtonVisible] = React.useState<boolean>(false);
    const [isToBeDeleted, setIsToBeDeleted] = React.useState<boolean>(false);

    const findCallbackFunction = (element: string): boolean => {
        return element === offering_id;
    };

    const findCheckedCallbackFunction = (element: string): boolean => {
        return element['offering_id'] === offering_id || element['offering_id'] === offering_id;
    };

    const appendOneItemHandlerHelper = (event): void => {
        event.stopPropagation();
        appendSelectOneItemHandler(offering_id);
    };

    const removeOneItemHandlerHelper = (event): void => {
        event.stopPropagation();
        removeSelectOneItemHandler(offering_id);
    };

    const onMouseEnterHandler = (): void => {
        setAreButtonVisible(true);
    };

    const onMouseLeaveHandler = (): void => {
        setAreButtonVisible(false);
    };

    const onDeleteButtonPressHandler = (event): void => {
        event.stopPropagation();
        setIsToBeDeleted(true);
    };

    const onAlertCancelHandler = (): void => {
        setIsToBeDeleted(false);
    };

    const onDeleteConfirmHandler = (): void => {
        deleteOneItemHandler(offering_id);
    };

    const appendCheckedOneItemHandlerHelper = (event): void => {
        event.stopPropagation();
        appendCheckedOneItemHandler(offering_id);
    };

    const removeCheckedOneItemHandlerHelper = (event): void => {
        event.stopPropagation();
        removeCheckedOneItemHandler(offering_id);
    };

    // const moreMenu = <Menu>
    //   <Menu.Item icon={isSelected ? 'ring' : 'circle'}
    //              text={isSelected ? 'Unselect' : 'Select'}
    //              intent={isSelected ? Intent.PRIMARY : null}
    //              onClick={isSelected ? removeOneItemHandlerHelper : appendOneItemHandlerHelper} />
    //   <Menu.Item icon={'trash'}
    //              text={'Delete'}
    //              intent={Intent.DANGER}
    //              onClick={onDeleteButtonPressHandler} />
    // </Menu>

    return (
        <section
            onMouseLeave={onMouseLeaveHandler}
            onMouseEnter={onMouseEnterHandler}
            css={css`
                width: 100%;
                ${isChecked && `opacity: 0.3`};
            `}>
            <Card
                onClick={
                    !!selectedItems[0]
                        ? isSelected
                            ? removeOneItemHandlerHelper
                            : appendOneItemHandlerHelper
                        : null
                }
                interactive={!!selectedItems[0]}
                css={css`
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    height: auto;
                    padding: 0.75rem;
                    ${isSelected && `box-shadow: 0 0 1px 2px ${theme.colors.primary} !important;`}
                `}>
                <div
                    css={css`
                        display: flex;
                    `}>
                    <ProgressiveImage
                        src={image}
                        alt={`Image of partner ${name}`}
                        width={96}
                        height={96}
                        className={'rounded-lg w-24 h-24'}
                    />
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            margin-left: 1rem;
                        `}>
                        <TitleBlock title={name} mode={'basic'} />
                    </div>
                </div>
                <ButtonGroup
                    vertical={true}
                    minimal={true}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    `}>
                    <Button
                        icon={isSelected ? 'circle' : 'ring'}
                        text={isSelected ? 'Unselect' : 'Select'}
                        intent={isSelected ? Intent.PRIMARY : null}
                        onClick={
                            isSelected ? removeOneItemHandlerHelper : appendOneItemHandlerHelper
                        }
                        css={css`
                            transition: 0.2s ease-in-out;
                            opacity: ${areButtonsVisible ? 1 : isSelected ? 1 : 0};
                        `}
                    />
                    <Button
                        icon={isChecked ? 'cross' : 'tick'}
                        minimal={true}
                        large={false}
                        text={isChecked ? 'Uncheck' : 'Check'}
                        onClick={
                            isChecked
                                ? removeCheckedOneItemHandlerHelper
                                : appendCheckedOneItemHandlerHelper
                        }
                        intent={isChecked ? null : Intent.PRIMARY}
                        css={css`
                            transition: 0.2s ease-in-out;
                            opacity: ${areButtonsVisible ? 1 : isSelected ? 1 : 0};
                        `}
                    />
                    <Button
                        icon={'trash'}
                        minimal={true}
                        large={false}
                        text={'Delete'}
                        onClick={onDeleteButtonPressHandler}
                        intent={Intent.DANGER}
                        css={css`
                            transition: 0.2s ease-in-out;
                            opacity: ${areButtonsVisible ? 1 : isSelected ? 1 : 0};
                        `}
                    />
                </ButtonGroup>
                <Alert
                    isOpen={isToBeDeleted}
                    cancelButtonText={'Do not delete'}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true}
                    confirmButtonText={'Delete'}
                    onCancel={onAlertCancelHandler}
                    intent={Intent.DANGER}
                    onConfirm={onDeleteConfirmHandler}>
                    <p>
                        Are you sure you want to delete <strong> {name} </strong> from this list?
                    </p>
                </Alert>
            </Card>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedItems: state.shareableListsPageState?.detailedSLSelectedItems,
        checkedItems: state.shareableListsPageState?.detailedSLCheckedItems
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        // deleteOneItemHandler: (itemId: string) => dispatch(AsyncOperations.deleteSelectedItemsDetailedSLAsync(itemId)),
        // appendSelectOneItemHandler: (itemId: string) => dispatch(Actions.appendItemDetailedSL(itemId)),
        // appendCheckedOneItemHandler: (itemId: string) => dispatch(Actions.appendCheckedItemDetailedSL(itemId)),
        // removeSelectOneItemHandler: (itemId: string) => dispatch(Actions.removeItemDetailedSL(itemId)),
        // removeCheckedOneItemHandler: (itemId: string) => dispatch(Actions.removeCheckedItemDetailedSL(itemId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SLOfferingCard);
