import gql from 'graphql-tag';

export const PartnerOpeningTimeAttributes = gql`
    fragment partnerOpeningTimeAttributes on PartnerOpeningTime {
        open {
            day
            time
        }
        close {
            day
            time
        }
        day
    }
`;

export const PartnerAddressAttributes = gql`
    fragment partnerAddressAttributes on PartnerAddress {
        address
        city
        country
        postal_code
        location {
            coordinates
        }
    }
`;

export const PartnerAttributes = gql`
    fragment partnerAttributes on Partner {
        _id
        name
        categories
        opening_times {
            ...partnerOpeningTimeAttributes
        }
        address {
            ...partnerAddressAttributes
        }
        images
        description
        tags
    }
    ${PartnerOpeningTimeAttributes}
    ${PartnerAddressAttributes}
`;
