import gql from 'graphql-tag';

export const CreateShareableList = gql`
    mutation createShareableList($input: ShareableListInput!) {
        createShareableList(input: $input) {
            __typename
            _id
        }
    }
`;

export const UpdateShareableListById = gql`
    mutation updateShareableListById($id: ID!, $input: ShareableListInput) {
        updateShareableListById(id: $id, input: $input)
    }
`;

export const DeleteShareableListById = gql`
    mutation deleteShareableListById($id: ID!) {
        deleteShareableListById(id: $id)
    }
`;

export const AddPartnerToShareableListById = gql`
    mutation addItemsToShareableListById($id: ID!, $input: ShareableListAddItemsInput!) {
        addItemsToShareableListById(id: $id, input: $input)
    }
`;

export const RemovePartnerFromShareableListById = gql`
    mutation removeItemsToShareableListById($id: ID!, $input: ShareableListRemoveItemsInput!) {
        removeItemsToShareableListById(id: $id, input: $input)
    }
`;
