import gql from 'graphql-tag';
import { SLAttributes } from './common';

export const GetShareableListById = gql`
    query getShareableListById($id: ID!) {
        getShareableListById(id: $id) {
            __typename
            ...slAttributes
        }
    }
    ${SLAttributes}
`;

export const ListShareableLists = gql`
    query ListShareableLists {
        listShareableLists {
            ...slAttributes
        }
    }
    ${SLAttributes}
`;

export const GetShareableListsByOwnerId = gql`
    query getShareableListsByOwnerId($owner_id: String!) {
        getShareableListsByOwnerId(owner_id: $owner_id) {
            ...slAttributes
            __typename
        }
    }
    ${SLAttributes}
`;
