import { ActionCreator, Dispatch } from 'redux';
import * as Actions from './actions';
import { ThunkAction } from 'redux-thunk';
import { GraphqlGatewayClient } from '@services/graphql-gateway';
import * as Types from '@interfaces/shareable-lists';
import {
    Types as SLSTypes,
    Queries as SLSQueries,
    Client as SLSClient,
    Mutations as SLSMutations
} from '@services/shareable-lists-service';

/**
 * Fetch shareable lists list on server side
 * @param SSR Amplify SSR component
 * @param userId ID of the user
 */
export const getShareableListsByOwnerIdSSRAsync = (SSR: any, userId: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { API: SSRAPI } = SSR;
            const shareableListsData = await GraphqlGatewayClient.call2<SLSTypes.GetShareableListsByOwnerIdQuery>(
                SLSQueries.GetShareableListsByOwnerId,
                SSRAPI,
                {
                    owner_id: userId
                }
            );

            const shareableLists = SLSClient.mapGetShareableListsByOwnerIdQuery(shareableListsData);
            if (shareableLists) {
                resolve(shareableLists);
            } else {
                resolve([]);
            }
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Creat a new list
 * @param input Input for creating list
 * @param ownerId ID of the user creating the list
 */
export const createListAsync: ActionCreator<ThunkAction<any, any, any, any>> = (
    input: Types.IShareableListCreateInput,
    ownerId: string
) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(Actions.createListAsync(input));

            const createShareableListResponseData = await GraphqlGatewayClient.call<SLSTypes.CreateShareableListMutation>(
                SLSMutations.CreateShareableList,
                {
                    input: {
                        owner_id: ownerId,
                        ...input
                    }
                }
            );

            const createShareableListResponse = SLSClient.mapCreateShareableListMutation(
                createShareableListResponseData
            );

            if (createShareableListResponse) {
                let shareableList = {
                    name: input.name,
                    _id: createShareableListResponse._id
                };
                dispatch(Actions.createListSucceeded(shareableList));
            } else {
                dispatch(
                    Actions.createListFailed(
                        new Error('Shareable list creation returned null: ' + JSON.stringify(input))
                    )
                );
            }
        } catch (err) {
            console.error(err);
            dispatch(Actions.createListFailed(err));
        }
    };
};

/**
 * Fetch shareable list by id on server side
 * @param SSR Amplify SSR component
 * @param id of the olist
 */
export const getShareableListsByIdSSRAsync = (SSR: any, id: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { API: SSRAPI } = SSR;
            const shareableListData = await GraphqlGatewayClient.call2<SLSTypes.GetShareableListByIdQuery>(
                SLSQueries.GetShareableListById,
                SSRAPI,
                {
                    id: id
                }
            );

            const shareableLists = SLSClient.mapGetShareableListByIdQuery(shareableListData);
            if (shareableLists) {
                resolve(shareableLists);
            } else {
                resolve([]);
            }
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Add partners to a list
 * @param input
 * @param listId
 * @param listName
 */
export const addPartnerToShareableListByIdAsync: ActionCreator<ThunkAction<any, any, any, any>> = (
    input: Types.ShareableListAddItemsInput,
    listId: string,
    listName: string
) => {
    return async (dispatch: Dispatch) => {
        // Dispatch update to add partner to list in state
        dispatch(Actions.addItemsToList(input, listName, listId));

        // Make the server call to add partner to list
        GraphqlGatewayClient.call<SLSTypes.AddItemsToShareableListByIdMutation>(
            SLSMutations.AddPartnerToShareableListById,
            {
                id: listId,
                input
            }
        )
            .then((addPartnerToShareableListByIdData) => {
                const addPartnerToShareableListByIdResponse = SLSClient.mapAddPartnersToShareableListByIdMutation(
                    addPartnerToShareableListByIdData
                );

                if (addPartnerToShareableListByIdResponse) {
                    dispatch(Actions.addItemsToListSucceeded(input, listId, listName));
                } else {
                    dispatch(
                        Actions.addItemsToListFailed(
                            input,
                            listId,
                            listName,
                            new Error('Add partner to shareable list failed')
                        )
                    );
                }
            })
            .catch((err) => {
                console.error(err);
                dispatch(Actions.addItemsToListFailed(input, listId, listName, err));
            });
    };
};

/**
 * Remove partners from a list
 * @param input
 * @param listId
 * @param listName
 */
export const removePartnersFromShareableListByIdAsync: ActionCreator<
    ThunkAction<any, any, any, any>
> = (input: Types.ShareableListRemoveItemsInput, listId: string, listName: string) => {
    return async (dispatch: Dispatch) => {
        // Dispatch update to add partner to list in state
        dispatch(Actions.removeItemsFromList(input, listName, listId));

        // Make the server call to add partner to list
        GraphqlGatewayClient.call<SLSTypes.RemoveItemsFromShareableListByIdMutation>(
            SLSMutations.RemovePartnerFromShareableListById,
            {
                id: listId,
                input
            }
        )
            .then((removePartnersFromShareableListByIdData) => {
                const removePartnersFromShareableListByIdResult = SLSClient.mapRemovePartnersFromShareableListByIdMutation(
                    removePartnersFromShareableListByIdData
                );

                if (removePartnersFromShareableListByIdResult) {
                    dispatch(Actions.removeItemsFromListSucceeded(input, listId, listName));
                } else {
                    dispatch(
                        Actions.removeItemsFromListFailed(
                            input,
                            listId,
                            listName,
                            new Error('Remove partner from shareable list failed')
                        )
                    );
                }
            })
            .catch((err) => {
                console.error(err);
                dispatch(Actions.removeItemsFromListFailed(input, listId, listName, err));
            });
    };
};

/**
 * Update a shareable list by id
 * @param input
 * @param listId
 */
export const updateListByIdAsync: ActionCreator<ThunkAction<any, any, any, any>> = (
    input: Partial<Types.IShareableListCreateInput>,
    listId: string
) => {
    return async (dispatch: Dispatch) => {
        // Make the server call to add partner to list
        GraphqlGatewayClient.call<SLSTypes.UpdateShareableListByIdMutation>(
            SLSMutations.UpdateShareableListById,
            {
                id: listId,
                input
            }
        )
            .then((updateShareableListByIdData) => {
                const updateShareableListByIdResult = SLSClient.mapUpdateShareableListByIdMutation(
                    updateShareableListByIdData
                );

                if (!updateShareableListByIdResult) {
                    dispatch(Actions.updateListByIdFailed(listId, new Error('Update list failed')));
                }
            })
            .catch((err) => {
                console.error(err);
                dispatch(Actions.updateListByIdFailed(listId, err));
            });
    };
};

export const deleteListByIdAsync: ActionCreator<ThunkAction<any, any, any, any>> = (
    userId: string,
    listId: string
) => {
    return async (dispatch: Dispatch) => {
        dispatch(Actions.deleteListByIdAsync(listId));

        // Make the server call to add partner to list
        GraphqlGatewayClient.call<SLSTypes.DeleteShareableListByIdMutation>(
            SLSMutations.DeleteShareableListById,
            {
                id: listId
            }
        )
            .then((deleteShareableListByIdData) => {
                const deleteShareableListByIdResult = SLSClient.mapDeleteShareableListByIdMutation(
                    deleteShareableListByIdData
                );

                if (!deleteShareableListByIdResult) {
                    dispatch(Actions.deleteListByIdFailed(listId, new Error('Update list failed')));
                }
            })
            .catch((err) => {
                console.error(err);
                dispatch(Actions.deleteListByIdFailed(listId, err));
            });
    };
};
