import { ICloseOpen } from '../../services/partners-service/types';

/**
 * Formates dateNumber to a displayable string
 * @param dateNumber Eg 700
 * @returns eg 07:00
 */
export const convertDateNumberToDisplayString = (dateNumber: any) => {
    if (dateNumber) {
        let dateNumberAsString = dateNumber.toString();
        if (dateNumberAsString.length < 4) {
            dateNumberAsString = '0' + dateNumberAsString;
        }

        return `${dateNumberAsString.slice(0, 2)}:${dateNumberAsString.slice(2)}`;
    }
    return dateNumber;
};

export const convertDateStringToFormattedDateString = (dateString: string): string => {
    const date = new Date(dateString);
    return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
};

/**
 * Returns current time in hours and minutes format
 * @returns eg. 23:00
 */
export const getCurrentHourMinutes = () => {
    let date = new Date();
    return date.getHours() + '' + date.getMinutes();
};

export const stringConverter = (startTime: ICloseOpen, endTime: ICloseOpen): [string, string] => {
    if (startTime.time === -1 || endTime.time === -1) {
        return ['', ''];
    } else {
        let convertedStartTime = convertDateNumberToDisplayString(startTime.time);
        let convertedEndTime = convertDateNumberToDisplayString(endTime.time);
        return [convertedStartTime, convertedEndTime];
    }
};

export const isOpenHandler = (
    startTime: ICloseOpen,
    currentTime: number,
    endTime: ICloseOpen,
    currentDayOfTheWeek: number
): boolean => {
    if (startTime.day < endTime.day) {
        if (
            (startTime.time <= currentTime && currentDayOfTheWeek === startTime.day) ||
            (currentTime <= endTime.time && currentDayOfTheWeek === endTime.day)
        ) {
            return true;
        }
        if (startTime.time <= currentTime && 0 === endTime.time) {
            return true;
        }
    } else {
        if (startTime.time <= currentTime && currentTime <= endTime.time) {
            return true;
        }
    }
};
