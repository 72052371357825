import React from 'react';
import { Card, H4, HTMLTable } from '@blueprintjs/core';
import { convertDateNumberToDisplayString } from '../../../../utils/date';
import { IOpeningTimes } from '../../../../services/partners-service/types';

const DAYS_OF_THE_WEEK = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

interface IOpeningTimesBlock {
    openingTimes: IOpeningTimes[];
}

const OpeningTimesBlock: React.FunctionComponent<IOpeningTimesBlock> = ({ openingTimes }) => {
    const headRenderer = (text: string, index) => {
        return <th key={index}>{text}</th>;
    };

    const rowRenderer = (texts: any[], id: string) => {
        return (
            <tr key={id}>
                {texts?.map((text: any, index) => {
                    return <td key={index}>{text.toString()}</td>;
                })}
            </tr>
        );
    };

    return (
        <Card className={'flex flex-col p-2 col-span-1'}>
            <H4> Opening times </H4>
            {openingTimes ? (
                <HTMLTable bordered={true} striped={true}>
                    <thead>
                        <tr>
                            {['Day', 'Open time', 'Closing time'].map((heading: string, index) =>
                                headRenderer(heading, index)
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {openingTimes.map((openingTime: IOpeningTimes, index: number) => {
                            return rowRenderer(
                                [
                                    DAYS_OF_THE_WEEK[index],
                                    convertDateNumberToDisplayString(openingTime.open.time),
                                    convertDateNumberToDisplayString(openingTime.close.time)
                                ],
                                openingTime._id
                            );
                        })}
                    </tbody>
                </HTMLTable>
            ) : (
                'Opening times about this partner is not available.'
            )}
        </Card>
    );
};

export default OpeningTimesBlock;
