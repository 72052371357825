import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../utils/theming/theme-type';
import { Geo } from '../../../../utils/geo';
import { Tooltip, Position } from '@blueprintjs/core';

interface IDistanceBlock {
    startCoordinates: [number, number];
    address: string;
    endCoordinates: {
        latitude: number;
        longitude: number;
    };
}

const DistanceBlock: React.FunctionComponent<IDistanceBlock> = ({
    startCoordinates,
    endCoordinates,
    address
}) => {
    const theme: Theme = useTheme();

    const startPosition = { latitude: startCoordinates[0], longitude: startCoordinates[1] };

    const getDistance = (): string => {
        return Geo.getPreciseDistance(startPosition, endCoordinates).toString().substring(0, 2);
    };

    const getShortDistance = (distance: string): string => {
        return distance.substring(0, 1) + '.' + distance.substring(1, 2);
    };

    return (
        <span
            css={css`
                font-size: ${theme.fontSize.default};
                color: ${theme.colors.secondary};
                text-align: start;
            `}>
            {startPosition && endCoordinates ? (
                <Tooltip content={address} position={Position.TOP}>
                    <span
                        css={css`
                            font-size: ${theme.fontSize.default};
                            color: black;
                        `}>
                        {getShortDistance(getDistance())} kms
                    </span>
                </Tooltip>
            ) : (
                <div></div>
            )}
        </span>
    );
};

export default DistanceBlock;
