import React from 'react';
import { css } from '@emotion/core';
import { IOffering } from '../../../../modules/products-display/types';
import { Button, Card, Dialog, Icon, Intent, Text, Tooltip } from '@blueprintjs/core';
import { ProgressiveImage } from '../../../common';
import { IconNames } from '@blueprintjs/icons';
import PriceBlock from '../../product-card/components/price-block';
import { ContactToBuy } from './index';
import { IContact } from '../../../../services/partners-service/types';

interface IMiniProductCard extends IOffering {
    isFetching?: boolean;
    contact?: IContact;
}

const MiniProductCard: React.FunctionComponent<IMiniProductCard> = ({
    name,
    image,
    is_discounted,
    details,
    discount,
    price,
    ref_price,
    isFetching,
    contact
}) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isContactDialogOpen, setIsContactDialogOpen] = React.useState<boolean>(false);

    const CONTENT = (
        <div className={'p-4'}>
            Important information:
            <ul>
                <li>
                    {' '}
                    The meat does not require inspection because farm operates on a small scale{' '}
                </li>
                <li>
                    {' '}
                    Bird is born on <strong> 15.04.2020 </strong>{' '}
                </li>
                <li>
                    {' '}
                    Slaughtered on <strong> 29.10.2020 </strong>{' '}
                </li>
                <li> Salmonella tested </li>
                <li> Grower: Kellon Lähiruoka, Kellontie 175 90820 </li>
            </ul>
        </div>
    );

    const dialogCloser = (): void => {
        setIsDialogOpen(false);
    };

    const dialogOpener = (): void => {
        setIsDialogOpen(true);
    };

    const contactDialogOpener = (): void => {
        setIsContactDialogOpen(true);
    };

    const contactDialogCloser = (): void => {
        setIsContactDialogOpen(false);
    };

    return (
        <Card className={'flex w-auto h-full p-2 justify-between'} interactive={false}>
            <div className={'w-auto h-auto'}>
                <ProgressiveImage src={image} className={'rounded-lg'} width={128} height={128} />
            </div>
            <div
                className={'flex flex-col text-end self-start items-end h-full justify-start'}
                css={css`
                    max-width: 55%;
                `}>
                <div
                    className={'flex items-end w-full mb-1'}
                    css={css`
                        max-width: 100%;
                    `}>
                    <Tooltip content={'Click for more info'} intent={Intent.WARNING}>
                        <Icon
                            icon={IconNames.INFO_SIGN}
                            iconSize={15}
                            onClick={dialogOpener}
                            intent={Intent.WARNING}
                            className={'mr-1 cursor-pointer'}
                        />
                    </Tooltip>
                    <Text ellipsize={true} className={'w-full text-right'}>
                        {name}
                    </Text>
                </div>
                <span
                    className={isFetching && 'bp3-skeleton'}
                    css={css`
                        color: grey;
                    `}>
                    <PriceBlock
                        isDiscounted={is_discounted}
                        discount={discount}
                        weight={details.weight}
                        price={price}
                        ref_price={ref_price}
                        size={'small'}
                    />
                </span>
                <Button
                    text={'Buy'}
                    intent={Intent.PRIMARY}
                    onClick={contactDialogOpener}
                    className={'mt-2'}
                />
                <Dialog
                    isOpen={isContactDialogOpen}
                    lazy={true}
                    isCloseButtonShown={true}
                    title={'Contact to buy'}
                    onClose={contactDialogCloser}
                    canEscapeKeyClose={true}
                    className={'bg-white pb-0'}
                    canOutsideClickClose={true}>
                    <ContactToBuy contact={contact} />
                </Dialog>
                <Dialog
                    isOpen={isDialogOpen}
                    lazy={true}
                    isCloseButtonShown={true}
                    title={'Important information'}
                    onClose={dialogCloser}
                    canEscapeKeyClose={true}
                    className={'bg-white pb-0'}
                    canOutsideClickClose={true}>
                    {CONTENT}
                </Dialog>
            </div>
        </Card>
    );
};

export default MiniProductCard;
