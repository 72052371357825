import { ButtonGroup, Card, Dialog, Intent, Menu, Popover } from '@blueprintjs/core';
import { css } from '@emotion/core';
import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Button } from '@components/ui';
import { ContactToBuy } from './index';
import { PartnerDto } from '@services/partners-service/dto';

interface IQuickAccessActions {
    partner: PartnerDto;
}

const QuickAccessActions: React.FC<IQuickAccessActions> = ({ partner }) => {
    const [isContactDialogOpen, setIsContactDialogOpen] = React.useState<boolean>(false);
    const [isFollowing, setIsFollowing] = React.useState<boolean>(false);

    const listsMenu = (
        <>
            <Menu.Item text={'Farms'} />
            <Menu.Item text={'Halal Stores/Restaurants'} />
            <Menu.Item text={'Knitting'} />
        </>
    );

    const onFollowHandler = (): void => {
        setIsFollowing(!isFollowing);
    };

    // const setIsContactDialogToOpen = (): void => {
    //   setIsContactDialogOpen(true)
    // }

    const setIsContactDialogClose = (): void => {
        setIsContactDialogOpen(false);
    };

    // const menu = <Menu>
    //   <Menu.Item text={'Add to list'} >
    //     { listsMenu }
    //   </Menu.Item>
    //   <Menu.Item text={'Follow'}
    //              icon={ isFollowing ? IconNames.TICK : null}
    //              onClick={onFollowHandler}
    //              intent={isFollowing ? Intent.PRIMARY : null} />
    //   <Menu.Divider />
    //   <Menu.Item text={'Report'} icon={IconNames.REMOVE} intent={Intent.DANGER} />
    // </Menu>

    return (
        <Card
            className={'p-2'}
            css={css`
                height: min-content;
            `}>
            <div className={'flex flex-row justify-start items-center'}>
                {/*{ isMobile ? <Popover content={menu}>*/}
                {/*    <Button icon={IconNames.MORE} intent={Intent.PRIMARY} minimal={true} />*/}
                {/*  </Popover>*/}
                {/*  :*/}
                <div className={'flex'}>
                    <Dialog
                        isOpen={isContactDialogOpen}
                        title={'Contact to buy'}
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}
                        isCloseButtonShown={true}
                        autoFocus={true}
                        className={'bg-white pb-0'}
                        onClose={setIsContactDialogClose}>
                        <ContactToBuy contact={partner.contact} />
                    </Dialog>
                    {/*<Button text={'Contact to buy'}*/}
                    {/*        onClick={setIsContactDialogToOpen}*/}
                    {/*        minimal={false}*/}
                    {/*        intent={Intent.PRIMARY}*/}
                    {/*        className={'mr-2'} />*/}
                    <ButtonGroup vertical={false} minimal={true}>
                        <Popover content={<Menu> {listsMenu} </Menu>}>
                            <Button text={'Add to list'} minimal={true} intent={Intent.PRIMARY} />
                        </Popover>
                        <Button
                            text={'Follow'}
                            minimal={true}
                            icon={isFollowing ? IconNames.TICK : null}
                            onClick={onFollowHandler}
                            intent={Intent.PRIMARY}
                        />
                        <Popover
                            content={
                                <Menu>
                                    <Menu.Item
                                        text={'Report'}
                                        icon={IconNames.REMOVE}
                                        intent={Intent.DANGER}
                                    />
                                </Menu>
                            }>
                            <Button intent={Intent.PRIMARY} minimal={true} icon={IconNames.MORE} />
                        </Popover>
                    </ButtonGroup>
                </div>
                {/*}*/}
            </div>
        </Card>
    );
};

export default QuickAccessActions;
