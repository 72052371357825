import React from 'react';
import { css } from '@emotion/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { H3 } from '@blueprintjs/core';
import { IDiscount, IPrice, IWeight } from '../../../../modules/products-display/types';
import DiscountBlock from './discount-block';

interface IPriceBlock {
    price: IPrice;
    isDiscounted?: boolean;
    discount?: IDiscount;
    weight?: IWeight;
    ref_price: IPrice;
    size: 'small' | 'default';
}

const PriceBlock: React.FunctionComponent<IPriceBlock> = ({
    price,
    isDiscounted,
    discount,
    weight,
    ref_price,
    size
}) => {
    const theme: Theme = useTheme();

    return (
        <div className={'flex flex-col items-end'}>
            <div className={'flex flex-col items-end'}>
                <DiscountBlock
                    is_discounted={isDiscounted}
                    weight={weight}
                    discount={discount}
                    price={price}
                />
                <H3
                    className={`m-0 ${isDiscounted && 'line-through font-normal text-right'}`}
                    css={css`
                        color: ${isDiscounted ? theme.colors.tertiary : theme.colors.secondary};
                        font-size: ${theme.fontSize.default} !important;
                    `}>
                    {`${price.price.toFixed(2)}€`} &#8226; {weight.weight}
                    {weight.unit}
                </H3>
            </div>
            <p
                className={'m-0 text-right'}
                css={css`
                    color: ${theme.colors.secondary};
                    font-size: ${size === 'default' && theme.fontSize.secondary}
                        ${size === 'small' && theme.fontSize.secondary};
                `}>
                {ref_price.price.toFixed(2)}€/{ref_price.unit}
            </p>
        </div>
    );
};

export default PriceBlock;
