import React from 'react';
import { Card, Collapse } from '@blueprintjs/core';
import { IShareableList } from '@services/shareable-lists-service/types';
import { css } from '@emotion/core';
import { theme } from '../../../utils/theming/theming';

interface ICompactShareableListCard {
    shareableList: IShareableList;
}

const CompactShareableListCard: React.FC<ICompactShareableListCard> = ({ shareableList }) => {
    const [isCollapseOpen, setIsCollapseOpen] = React.useState<boolean>(false);

    const isCollapseOpenStateChanger = () => {
        setIsCollapseOpen(!isCollapseOpen);
    };

    return (
        <Card className={'p-2'} onClick={isCollapseOpenStateChanger} interactive={true}>
            <div className={'flex justify-between items-center'}>
                {/*<Button text={shareableList.name}*/}
                {/*        onClick={isCollapseOpenStateChanger}*/}
                {/*        minimal={true}*/}
                {/*        intent={Intent.PRIMARY} />*/}
                <span
                    css={css`
                        color: ${theme.colors.primary};
                    `}>
                    {' '}
                    {shareableList.name}{' '}
                </span>
                <span
                    className={
                        'flex justify-center items-center w-5 h-5 border-solid bg-white rounded-full'
                    }
                    css={css`
                        color: ${theme.colors.tertiary};
                    `}>
                    {shareableList.items.partners.length}
                </span>
            </div>
            <Collapse isOpen={isCollapseOpen}>
                {shareableList.items.partners.map((partner) => {
                    return <div> {partner.partner.name} </div>;
                })}
            </Collapse>
        </Card>
    );
};

export default CompactShareableListCard;
