import { GraphQLResult } from '@aws-amplify/api';
import * as Queries from './graphql/queries';
import * as Mutations from './graphql/mutations';
import * as Types from './types.d';

export class Client {
    static mapGetShareableListByIdQuery(
        getShareableListByIdQuery: GraphQLResult<Types.GetShareableListByIdQuery> = {}
    ): Types.IShareableList | {} {
        return getShareableListByIdQuery.data?.getShareableListById?._id
            ? getShareableListByIdQuery.data?.getShareableListById
            : {};
    }

    static mapGetShareableListsByOwnerIdQuery(
        getShareableListsByOwnerIdQuery: GraphQLResult<Types.GetShareableListsByOwnerIdQuery> = {}
    ): Types.IShareableList[] | [] {
        return getShareableListsByOwnerIdQuery.data?.getShareableListsByOwnerId;
    }

    static mapCreateShareableListMutation(
        createShareableListMutation: GraphQLResult<Types.CreateShareableListMutation> = null
    ): Types.CreateShareableListResult {
        return createShareableListMutation.data?.createShareableList?._id
            ? createShareableListMutation.data?.createShareableList
            : null;
    }

    static mapUpdateShareableListByIdMutation(
        updateShareableListByIdMutation: GraphQLResult<Types.UpdateShareableListByIdMutation> = null
    ): boolean | null {
        return updateShareableListByIdMutation.data?.updateShareableListById ?? null;
    }

    static mapAddPartnersToShareableListByIdMutation(
        addPartnerToShareableListByIdMutation: GraphQLResult<Types.AddItemsToShareableListByIdMutation> = null
    ): boolean | null {
        return addPartnerToShareableListByIdMutation.data?.addItemsToShareableListById ?? null;
    }

    static mapRemovePartnersFromShareableListByIdMutation(
        removeItemsToShareableListMutation: GraphQLResult<Types.RemoveItemsFromShareableListByIdMutation> = null
    ): boolean | null {
        return removeItemsToShareableListMutation.data?.removeItemsToShareableListById ?? null;
    }

    static mapDeleteShareableListByIdMutation(
        deleteShareableListByIdMutation: GraphQLResult<Types.DeleteShareableListByIdMutation> = null
    ): boolean | null {
        return deleteShareableListByIdMutation.data?.deleteShareableListById ?? null;
    }
}
