import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Tag, Tooltip, Intent } from '@blueprintjs/core';
import { IOpeningTimes } from '../../../../services/partners-service/types';
import { getCurrentHourMinutes, isOpenHandler, stringConverter } from '../../../../utils/date';

interface IOpeningTimesBadge {
    openingTimes: IOpeningTimes[];
}

const OpeningTimesBadge: React.FunctionComponent<IOpeningTimesBadge> = ({ openingTimes }) => {
    const [openTime, setOpenTime] = useState<[string, string]>(['', '']);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        let date = new Date();
        let currentDayOfTheWeek = date.getDay() === 0 ? 7 : date.getDay();
        for (let i = 0; i < 6; i++) {
            if (openingTimes?.length <= 1) {
                setOpenTime(['', '']);
                setIsOpen(false);
                continue;
            }
            if (currentDayOfTheWeek === openingTimes?.[i]?.day) {
                const convertedTime = stringConverter(openingTimes[i].open, openingTimes[i].close);
                if (convertedTime[0] === '') {
                    setIsOpen(false);
                } else {
                    const currentTime = getCurrentHourMinutes();
                    setIsOpen(
                        isOpenHandler(
                            openingTimes[i].open,
                            parseInt(currentTime),
                            openingTimes[i].close,
                            currentDayOfTheWeek
                        )
                    );
                }
                setOpenTime([...convertedTime]);
            }
            if (currentDayOfTheWeek === -1) {
                const convertedTime = stringConverter(openingTimes[0].open, openingTimes[0].close);
                if (convertedTime[0] === '') {
                    setIsOpen(false);
                } else {
                    const currentTime = getCurrentHourMinutes();
                    setIsOpen(
                        isOpenHandler(
                            openingTimes[i].open,
                            parseInt(currentTime),
                            openingTimes[i].close,
                            currentDayOfTheWeek
                        )
                    );
                }
                setOpenTime([...convertedTime]);
            }
        }
    }, []);

    if (openTime[0] && openTime[1]) {
        return (
            <Tooltip
                content={openTime[0] + ' - ' + openTime[1]}
                position={'top'}
                intent={'primary'}>
                <Tag
                    intent={isOpen ? Intent.SUCCESS : Intent.DANGER}
                    className={'p-2'}
                    css={css`
                        color: white;
                        font-weight: bold;
                        line-height: normal;
                        margin-bottom: 0.2rem;
                    `}>
                    {isOpen ? 'open' : 'closed'}
                </Tag>
            </Tooltip>
        );
    } else {
        return null;
    }
};

export default OpeningTimesBadge;
