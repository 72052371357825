import { IContact } from '@interfaces/partner';
import React from 'react';

interface IContactToBuy {
    contact: IContact;
}

const ContactToBuy: React.FunctionComponent<IContactToBuy> = ({ contact }) => {
    return (
        <div className={'flex flex-col p-4'}>
            <div className={'text-lg'}>Contact the partner in order to buy the product</div>
            <div className={'flex flex-col my-2'}>
                <span className={'font-bold my-1'}> {contact.name} </span>
                <span className={'my-1'}> {contact.phone_number} </span>
                <a className={'my-1'} href={contact.website}>
                    {' '}
                    {contact.website}{' '}
                </a>
            </div>
        </div>
    );
};

export default ContactToBuy;
