import React from 'react';
import { css } from '@emotion/core';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import * as Types from '@interfaces/shareable-lists';

const DESCRIPTION_INPUT_CHARACTER_LIMIT: number = 140;
const TITLE_INPUT_CHARACTER_LIMIT: number = 40;

interface IShareableListCreateForm {
    createListHandler: (input: Types.IShareableListCreateInput) => void;
    closeCreateListDialogHandler: () => void;
    isCreating: boolean;
}

const ShareableListCreateForm: React.FunctionComponent<IShareableListCreateForm> = (props) => {
    const { register, watch, handleSubmit, errors } = useForm();
    const createShareableListHandler = (data: Types.IShareableListCreateInput) => {
        // CREATE LIST
        props.createListHandler(data);
        props.closeCreateListDialogHandler();
    };

    const watchTitle: string = watch('name');
    const watchDescription: string = watch('description');

    return (
        <form
            onSubmit={handleSubmit(createShareableListHandler)}
            css={css`
                display: flex;
                flex-direction: column;
                padding: 1.25rem;
                background-color: white;
                border-radius: 0.5rem;
            `}>
            <FormGroup
                labelFor={'name'}
                label={'Name'}
                labelInfo={`(required) ${watchTitle?.length}/${TITLE_INPUT_CHARACTER_LIMIT}`}
                helperText={
                    errors?.name
                        ? 'Name field should not be empty'
                        : 'The name of your shareable list'
                }>
                <InputGroup
                    placeholder={'E.g. WEDNESDAY PARTY!!!!'}
                    large={false}
                    name={'name'}
                    intent={errors?.name ? Intent.DANGER : null}
                    maxLength={TITLE_INPUT_CHARACTER_LIMIT}
                    type={'text'}
                    inputRef={register({
                        required: true
                    })}
                    id={'name'}
                />
            </FormGroup>
            <FormGroup
                labelFor={'description'}
                label={'Description'}
                labelInfo={`${watchDescription?.length}/${DESCRIPTION_INPUT_CHARACTER_LIMIT}`}
                helperText={'The description of your shareable list'}>
                <InputGroup
                    placeholder={
                        'E.g. Description of the list, what it is for and what should be done about it'
                    }
                    large={false}
                    type={'text'}
                    multiple={true}
                    maxLength={DESCRIPTION_INPUT_CHARACTER_LIMIT}
                    inputRef={register}
                    name={'description'}
                    id={'description'}
                />
            </FormGroup>
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}>
                <Button
                    text={'Close'}
                    onClick={props.closeCreateListDialogHandler}
                    css={css`
                        margin-right: 1rem;
                    `}
                />
                <Button
                    intent={Intent.PRIMARY}
                    loading={props.isCreating}
                    text={'Create'}
                    type={'submit'}
                />
            </div>
        </form>
    );
};

export default ShareableListCreateForm;
