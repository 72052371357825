import React from 'react';
import { Button, Intent, Menu } from '@blueprintjs/core';
import { IShareableList } from '@interfaces/shareable-lists';
import { PartnerDto } from '@services/partners-service/dto';
import Router from 'next/router';
import { ToasterShower } from '../../../index';

interface IListsMenu {
    hit: PartnerDto;
    shareableLists: IShareableList[];
    addPartnerToShareableListByIdAsync: (
        partnerId: string,
        listId: string,
        listName: string
    ) => void;
    userId: string | undefined;
    // createListHandler: (input: IShareableListCreateInput) => void;
    // isCreating?: boolean;
}

const ListsMenu: React.FunctionComponent<IListsMenu> = ({
    hit,
    shareableLists,
    addPartnerToShareableListByIdAsync,
    userId,
    ...props
}) => {
    const openCreateDialog = (): void => {
        Router.push('/lists');
    };

    const authorizePusher = () => {
        Router.push('/auth');
    };

    const addPartnerToShareableListByIdAsyncHandler = (shareableList: IShareableList): void => {
        addPartnerToShareableListByIdAsync(hit.id, shareableList._id, shareableList.name);
    };

    if (!userId) {
        return (
            <>
                <span> Authorize to add it to a list </span>
                <Menu.Divider />
                <Button
                    text={'Press this to authorize'}
                    fill={true}
                    onClick={authorizePusher}
                    intent={Intent.PRIMARY}
                />
            </>
        );
    } else {
        return (
            <>
                {shareableLists && shareableLists.length > 0 ? (
                    shareableLists?.map((shareableList) => {
                        return (
                            <>
                                <Menu.Item
                                    text={shareableList['name']}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        addPartnerToShareableListByIdAsyncHandler(shareableList);
                                        ToasterShower.show({
                                            message: `Partner was successfully added to ${shareableList.name}`,
                                            intent: Intent.SUCCESS
                                        });
                                    }}
                                    key={shareableList._id}
                                />
                            </>
                        );
                    })
                ) : (
                    <Menu.Item text={'No lists created yet'} disabled={true} />
                )}
                <Menu.Divider />
                <Button
                    text={'Create a new list'}
                    fill={true}
                    intent={Intent.PRIMARY}
                    onClick={openCreateDialog}
                />
            </>
        );
    }
};

export default ListsMenu;
