import { IAddress, IOpeningTimes } from '../partners-service/types';

export declare interface IShareableListItemPartner {
    _id: string;
    partner: IShareableListItemPartnerSub;
    added_by: string;
    note?: string;
    added_at: string;
}

export interface IShareableListItemPartnerSub {
    _id: string;
    name: string;
    address?: IAddress;
    isTrulyLocal?: boolean;
    images?: string[];
    tags?: string[];
    categories: string[];
    opening_times: IOpeningTimes[];
}

export interface IShareableListCollaborator {
    user_id: string;
    name: string;
    profile_picture: string;
    access_level: ShareableListCollaboratorAccessLevels;
    added_at: string;
    _id?: string;
}

enum ShareableListCollaboratorAccessLevels {
    READ,
    WRITE,
    ADMIN
}

enum ShareableListPrivacyLevels {
    PUBLIC,
    PRIVATE
}

export declare interface IShareableListItems {
    partners?: IShareableListItemPartner[];
}

export type ShareableListItem = 'partner' | 'offering';

export declare interface IShareableList {
    owner_id: string;
    _id: string;
    created_at: string;
    updated_at: string;
    name: string;
    description?: string;
    is_collaborated: Boolean;
    privacy: ShareableListPrivacyLevels;
    items: IShareableListItems;
    collaborators?: [IShareableListCollaborator];
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface ICreateShareableListInput extends Omit<Partial<IShareableList>, '_id'> {
    owner_id: string;
    name: string;
}

interface ShareableListInput extends Partial<IShareableList> {}

// Queries

export type ListShareableListsQuery = {
    listShareableLists: IShareableList[];
};

export type GetShareableListByIdQuery = {
    getShareableListById: IShareableList;
};

export type GetShareableListsByOwnerIdQuery = {
    getShareableListsByOwnerId: IShareableList[];
};

// Mutations

export type CreateShareableListMutation = {
    createShareableList: CreateShareableListResult;
};

export type CreateShareableListResult = {
    _id: string;
};

export type UpdateShareableListByIdMutation = {
    updateShareableListById: boolean;
};

export type DeleteShareableListByIdMutation = {
    deleteShareableListById: boolean;
};

// UPDATING ITEMS

export type AddPartnersToListInput = {
    partners: AddPartnersToListSubInput[];
};

export type AddPartnersToListItemsDivisionInput = {
    partners: AddPartnersToListInput;
};

export type AddItemsToShareableListByIdMutationInput = {
    addItemsToShareableListById: AddPartnersToListItemsDivisionInput;
};

export type AddItemsToShareableListByIdMutation = {
    addItemsToShareableListById: boolean;
};

export type RemovePartnersFromListInput = {
    ids: string[];
};

export type RemoveItemsFromListItemsDivisionInput = {
    partners: RemovePartnersFromListInput;
};

export type RemoveItemsFromShareableListByIdMutationInput = {
    removeItemsToShareableListById: RemoveItemsFromListItemsDivisionInput;
};

export type RemoveItemsFromShareableListByIdMutation = {
    removeItemsToShareableListById: boolean;
};
