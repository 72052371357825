import React from 'react';
import { css } from '@emotion/core';
import ShareableListButtons from './components/shareable-lists/shareable-list-buttons';
import ShareableListsList from './components/shareable-lists/shareable-lists-list';
import { MaxWidthWrapper } from '../../../components/common';
import { Card, Divider } from '@blueprintjs/core';

interface IShareableListsPageIndexProps {
    userId: string;
}

const ShareableListsPageIndex: React.FunctionComponent<IShareableListsPageIndexProps> = (props) => {
    return (
        <main
            css={css`
                margin-top: 3rem;
                background-color: white;
                min-height: calc(100vh - 3rem);
                display: flex;
                justify-content: center;
            `}>
            <MaxWidthWrapper color={'white'}>
                <div
                    css={css`
                        padding-top: 1rem;
                        padding-bottom: 10rem;
                    `}>
                    <Card
                        css={css`
                            display: flex;
                            flex-direction: column;
                            padding: 1rem;
                        `}>
                        <ShareableListButtons userId={props.userId} />
                        <Divider />
                        <ShareableListsList />
                    </Card>
                </div>
            </MaxWidthWrapper>
        </main>
    );
};

export default ShareableListsPageIndex;
