import React from 'react';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { Button } from '@components/ui';

const SPINNER_SIZE: number = 50;

export interface IwithRenderUI {
    error: boolean;
    isFetching: boolean;
    data?: any;
    props?: any;
    hits?: any;
}

/**
 * HOC that handles error, loading and no results component rendering
 * @param Component - The component that will be rendered is there are no errors, fetching going on and the data was successfully fetched
 */
const withRenderUI = <P extends object>(
    Component: React.ComponentType<P>
): React.FC<P & IwithRenderUI> => ({ error, isFetching, hits, data, ...props }: IwithRenderUI) => {
    const refreshHandler = (): false => {
        window.location.reload();
        return false;
    };

    if (error) {
        return (
            <NonIdealState
                icon={'error'}
                title={'Ooops, something went wrong'}
                description={
                    'Try to refresh the page or contact us if the problem does not disappear'
                }
                action={
                    <Button intent={'primary'} text={'Refresh the page'} onClick={refreshHandler} />
                }
            />
        );
    }
    if (isFetching) {
        return <Spinner intent={'primary'} size={SPINNER_SIZE} />;
    }
    if (hits?.length === 0 || data?.length === 0) {
        return (
            <NonIdealState
                icon={'search'}
                title={'No results'}
                description={'Try to search for something else'}
            />
        );
    }
    if (hits?.length) {
        return <Component {...(props as P)} hits={hits} />;
    }
    if (data?.length) {
        return <Component {...(props as P)} data={data} />;
    }
    return <Component {...(props as P)} />;
};

export default withRenderUI;
