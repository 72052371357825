import API, { GRAPHQL_AUTH_MODE, graphqlOperation, GraphQLResult } from '@aws-amplify/api';

export interface GraphQLOptions {
    input?: object;
    variables?: object;
    authMode?: GRAPHQL_AUTH_MODE;
    id?: string | number;
    owner_id?: string;
}

class GraphqlGatewayClient {
    /**
     * Call the graphql gateway
     * @param query GraphQL query
     * @param options GraphQL or AppSync options
     * @param APIClient GraphQL gateway AppSync client
     */
    static async call<T>(query: any, options?: GraphQLOptions): Promise<GraphQLResult<T>> {
        return (await API.graphql(graphqlOperation(query, options))) as GraphQLResult<T>;
    }

    static async call2<T>(
        query: any,
        APIClient: typeof API,
        options: GraphQLOptions = {}
    ): Promise<GraphQLResult<T>> {
        return (await APIClient.graphql({ query, variables: { ...options } })) as GraphQLResult<T>;
    }
}

export { GraphqlGatewayClient };
