import * as ActionTypes from '../../action.types';
import {
    IShareableList,
    IShareableListCreateInput,
    ShareableListAddItemsInput,
    ShareableListRemoveItemsInput
} from '@interfaces/shareable-lists';

// FETCH LISTS BY OWNER ID

export const listsFetchByOwnerIdAsync = () => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_ASYNC
    };
};

export const listsFetchByOwnerIdSucceeded = (shareableLists: IShareableList[]) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_SUCCESS,
        payload: {
            shareableLists
        }
    };
};

export const listsFetchByOwnerIdFailed = (error: Error) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_OWNER_ID_FAILED
    };
};

// FETCH LIST BY ID

export const listsFetchByIdAsync = () => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_ID_ASYNC
    };
};

export const listsFetchByIdSucceeded = (shareableList: IShareableList) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_ID_SUCCESS,
        payload: {
            shareableList
        }
    };
};

export const listsFetchByIdFailed = (error: Error) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_FETCH_BY_ID_FAILED
    };
};

// CREATE LIST

export const createListAsync = (input: IShareableListCreateInput) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_CREATE_LIST_ASYNC,
        payload: {
            input
        }
    };
};

export const createListSucceeded = (shareableList: { name: string; _id: string }) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_CREATE_LIST_SUCCESS,
        payload: {
            shareableList
        }
    };
};

export const createListFailed = (error: Error) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_CREATE_LIST_FAILED,
        payload: {
            error
        }
    };
};

// UPDATE LIST

export const updateListByIdAsync = (listId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_ASYNC,
        payload: {
            listId
        }
    };
};

export const updateListByIdSucceeded = (listId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_SUCCESS,
        payload: {
            listId
        }
    };
};

export const updateListByIdFailed = (listId: string, error: Error) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_FAILED,
        payload: {
            listId,
            error
        }
    };
};

// DELETE LIST

export const deleteListByIdAsync = (listId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DELETE_BY_ID_ASYNC,
        payload: {
            listId
        }
    };
};

export const deleteListByIdSucceeded = (listId: string) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DELETE_BY_ID_SUCCESS,
        payload: {
            listId
        }
    };
};

export const deleteListByIdFailed = (listId: string, err: Error) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DELETE_BY_ID_FAILED,
        payload: {
            listId,
            err
        }
    };
};

// ADD ITEMS TO LIST

export const addItemsToListSucceeded = (
    items: ShareableListAddItemsInput,
    listId: string,
    listName: string
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_SUCCESS,
        payload: {
            items,
            listId,
            listName
        }
    };
};

export const addItemsToListFailed = (
    items: ShareableListAddItemsInput,
    listId: string,
    listName: string,
    error: Error
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_ADD_ITEMS_TO_LIST_FAILED,
        payload: {
            error,
            items,
            listId,
            listName
        }
    };
};

// REMOVE ITEMS FROM LIST

export const removeItemsFromListSucceeded = (
    items: ShareableListRemoveItemsInput,
    listId: string,
    listName: string
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_SUCCESS,
        payload: {
            items,
            listId,
            listName
        }
    };
};

export const removeItemsFromListFailed = (
    items: ShareableListRemoveItemsInput,
    listId: string,
    listName: string,
    error: Error
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_DETAILED_PAGE_REMOVE_ITEMS_FROM_LIST_FAILED,
        payload: {
            error,
            items,
            listId,
            listName
        }
    };
};

export const updateListFailed = (
    update: Partial<IShareableListCreateInput>,
    listId: string,
    error: Error
) => {
    return {
        type: ActionTypes.SHAREABLE_LISTS_MODULE_UPDATE_BY_ID_FAILED,
        payload: {
            update,
            listId,
            error
        }
    };
};
