import gql from 'graphql-tag';
import { CommonGraphql } from '../../partners-service';

export const SLItemPartnerAttributes = gql`
    fragment sLItemPartnerAttributes on ShareableListPartner {
        _id
        partner {
            ...partnerAttributes
        }
        added_by
        note
        added_at
    }
    ${CommonGraphql.PartnerAttributes}
`;

export const SLItemsAttributes = gql`
    fragment sLItemsAttributes on ShareableListItems {
        partners {
            ...sLItemPartnerAttributes
        }
    }
    ${SLItemPartnerAttributes}
`;

export const SLCollaboratorAttributes = gql`
    fragment sLCollaboratorAttributes on ShareableListCollaborator {
        user_id
        name
        profile_picture
        access_level
        added_at
    }
`;

export const SLAttributes = gql`
    fragment slAttributes on ShareableList {
        owner_id
        _id
        created_at
        updated_at
        name
        description
        is_collaborated
        privacy
        collaborators {
            ...sLCollaboratorAttributes
        }
        items {
            ...sLItemsAttributes
        }
    }
    ${SLCollaboratorAttributes}
    ${SLItemsAttributes}
`;
