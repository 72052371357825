import React from 'react';
import { QuickAccessActions } from './index';
import { PartnerDto } from '@services/partners-service/dto';
import ContactsBlock from './contacts-block';
import { css } from '@emotion/core';
import { theme } from '../../../../utils/theming/theming';
import useMedia from 'use-media';

interface IQuickAccessContainer {
    partner: PartnerDto;
}

const QuickAccessContainer: React.FC<IQuickAccessContainer> = ({ partner }) => {
    const isMobile: boolean = useMedia({ maxWidth: 640 });

    return (
        <aside
            className={
                'bottom-0 col-span-12 p-2 sticky grid auto-rows-min gap-2 ' + 'md:col-span-4 sm:p-0'
            }
            css={css`
                z-index: 5;
                height: min-content;
                background-color: white;
                ${theme.layout.mq[0]} {
                    top: 4rem;
                }
            `}>
            <QuickAccessActions partner={partner} />
            {!isMobile && (
                <ContactsBlock
                    iconSize={20}
                    partnerName={partner.name}
                    {...partner.contact}
                    {...partner.address}
                />
            )}
        </aside>
    );
};

export default QuickAccessContainer;
