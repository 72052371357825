import React from 'react';
import { Menu, Popover, Intent, Position, Alignment } from '@blueprintjs/core';
import { IShareableList } from '@interfaces/shareable-lists';
import { ListsMenu } from './index';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/core';
import { Button } from '@components/ui';
import { PartnerDto } from '@services/partners-service/dto';

interface IActionsBlock {
    hit: PartnerDto;
    userId: string | undefined;
    shareableLists: IShareableList[];
    addPartnerToShareableListByIdAsync: (
        partnerId: string,
        listId: string,
        listName: string
    ) => void;
}

const ActionsBlock: React.FunctionComponent<IActionsBlock> = ({
    hit,
    userId,
    shareableLists,
    addPartnerToShareableListByIdAsync
}) => {
    const listsMenu = (
        <Menu>
            <ListsMenu
                hit={hit}
                userId={userId}
                addPartnerToShareableListByIdAsync={addPartnerToShareableListByIdAsync}
                shareableLists={shareableLists}
            />
        </Menu>
    );

    const propagationStopper = (event: any): void => {
        event.stopPropagation();
    };

    return (
        <section className={'flex justify-end'} onClick={propagationStopper}>
            <Popover content={listsMenu} position={Position.BOTTOM}>
                <Button
                    icon={IconNames.PLUS}
                    minimal={false}
                    intent={Intent.PRIMARY}
                    fill={false}
                    alignText={Alignment.CENTER}
                    className={'rounded-full'}
                    css={css`
                        border-radius: 9999rem;
                    `}
                />
            </Popover>
        </section>
    );
};

export default ActionsBlock;
