import { getPreciseDistance } from 'geolib';

export declare interface ICoordinate {
    latitude: number;
    longitude: number;
}

export class Geo {
    /**
     * Get precise distance between two points
     * @param start
     * @param end
     */
    static getPreciseDistance(start: ICoordinate, end: ICoordinate) {
        return getPreciseDistance(start, end);
    }
}
