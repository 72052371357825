import React from 'react';
import { css } from '@emotion/core';
import { IDiscount, IWeight } from '../../../../modules/products-display/types';
import { IPrice } from '../../../../services/partners-service/types';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';

interface IDiscountBlock {
    is_discounted: boolean;
    discount: IDiscount;
    weight?: IWeight;
    price: IPrice;
}

const DiscountBlock: React.FunctionComponent<IDiscountBlock> = ({
    discount,
    is_discounted,
    weight,
    price
}) => {
    const theme: Theme = useTheme();

    const discountHandler = (): number => {
        if (discount?.new_price) {
            return discount?.new_price;
        }
        if (discount?.percentage) {
            const discountedPrice: number = price.price * ((100 - discount?.percentage) / 100);
            return parseFloat(discountedPrice.toFixed(2));
        }
    };

    if (is_discounted) {
        return (
            <div className={'flex items-center'}>
                <div
                    className={
                        'w-auto rounded font-bold p-1 flex text-right text-sm text-white mr-1'
                    }
                    css={css`
                        background-color: ${theme.colors.danger};
                    `}>
                    {discount?.new_price && 'New!'}
                    {discount?.percentage && `-` + discount?.percentage + '%'}
                </div>
                <h3 className={'font-bold m-0 text-base mr-1 text-black text-right'}>
                    {`${discountHandler()}€`} &#8226; {weight.weight}
                    {weight.unit}
                </h3>
            </div>
        );
    } else {
        return null;
    }
};

export default DiscountBlock;
