import React, { useState } from 'react';
import { css } from '@emotion/core';
import { IShareableList } from '@interfaces/shareable-lists';
import { IconNames } from '@blueprintjs/icons';
import { Alert, Card, ContextMenu, H4, Intent, Menu, Popover, Text } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../utils/theming/theme-type';
import { convertDateStringToFormattedDateString } from '../../../utils/date';
import { useRouter } from 'next/router';
import { ToasterShower } from '../../index';
import { Button } from '@components/ui';
import { connect } from 'react-redux';

interface IShareableListCard {
    shareableList: IShareableList;
    deleteList: (listId: string) => void;
    deletingListId: string;
    error: Error;
}

const ShareableListCard: React.FunctionComponent<IShareableListCard> = ({
    deletingListId,
    error,
    shareableList,
    ...props
}): any => {
    const router = useRouter();
    const theme: Theme = useTheme();

    React.useEffect(() => {
        console.log('this gets triggered');
        if (deletingListId === shareableList._id && error) {
            setIsOptimisticallyDeleted(false);
            ToasterShower.show({
                message: 'Delete has been failed',
                intent: Intent.DANGER,
                icon: 'warning-sign'
            });
        }
    }, [deletingListId, error]);

    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
    const [isMoreButtonVisible, setIsMoreButtonVisible] = useState<boolean>(false);
    const [isContextMenu, setIsContextMenu] = useState<boolean>(false);
    const [isOptimisticallyDeleted, setIsOptimisticallyDeleted] = useState<boolean>(false);

    const onShareClickHandler = (event: any): void => {
        event.stopPropagation();
        navigator.clipboard.writeText(`${window.location.href}/${shareableList._id}`);
        ToasterShower.show({
            message: 'Link was copied to a clipboard!',
            intent: Intent.SUCCESS
        });
    };

    const alertOpenHandler = (): void => {
        setIsAlertOpen(true);
    };

    const contextMenu = (
        <Menu>
            <Menu.Item text={'Share'} onClick={onShareClickHandler} intent={Intent.PRIMARY} />
            <Menu.Divider />
            <Menu.Item text={'Delete'} intent={Intent.DANGER} onClick={alertOpenHandler} />
        </Menu>
    );

    const onAlertCancelHandler = (event): void => {
        event.stopPropagation();
        setIsAlertOpen(false);
    };

    const onDeleteButtonHandler = (event): void => {
        event.stopPropagation();
        props.deleteList(shareableList._id);
        setIsAlertOpen(false);
    };

    const onClickHandler = (): void => {
        router.push(`/lists/${shareableList._id}`);
    };

    const contextMenuOpener = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setIsContextMenu(true);
        ContextMenu.show(
            contextMenu,
            { left: event.clientX, top: event.clientY },
            contextMenuCloser
        );
    };

    const contextMenuCloser = () => {
        setIsContextMenu(false);
    };

    const onMouseEnterHandler = (): void => {
        setIsMoreButtonVisible(true);
    };

    const onMouseLeaveHandler = (): void => {
        setIsMoreButtonVisible(false);
    };

    const getCreatedAtDate = (): string => {
        if (shareableList['created_at']) {
            return convertDateStringToFormattedDateString(shareableList['created_at']);
        } else {
            return convertDateStringToFormattedDateString(new Date().toString());
        }
    };

    return (
        <section
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onContextMenu={contextMenuOpener}
            css={css`
                display: ${isOptimisticallyDeleted && 'none'};
            `}>
            <Card
                interactive={true}
                elevation={isContextMenu && 4}
                onClick={onClickHandler}
                className={'flex justify-between h-auto w-auto p-2 max-w-xs'}
                css={css`
                    min-width: 10rem;
                `}>
                <div className={'flex flex-col justify-between w-full'}>
                    <Text
                        ellipsize={true}
                        css={css`
                            margin: auto;
                            width: 100%;
                            font-size: ${theme.fontSize.tertiary};
                            color: ${theme.colors.tertiary};
                            margin-bottom: 0.25rem;
                        `}>
                        {getCreatedAtDate()}
                    </Text>
                    <Text
                        ellipsize={false}
                        css={css`
                            width: 100%;
                            max-width: 20rem;
                            font-weight: bold;
                            font-size: ${theme.fontSize.default};
                            color: ${theme.colors.secondary};
                            margin-bottom: 0.25rem;
                            word-break: break-word;
                        `}>
                        {shareableList['name']}
                    </Text>
                    <Text ellipsize={false} className={'w-full text-sm break-words'}>
                        {shareableList['description'] || ''}
                    </Text>
                </div>
                <div
                    className={'flex flex-col justify-between items-center pl-2'}
                    onClick={(event) => event.stopPropagation()}>
                    <Popover content={contextMenu}>
                        <Button
                            icon={IconNames.MORE}
                            minimal={true}
                            css={css`
                                transition: 0.2s ease-in-out;
                                opacity: ${isMoreButtonVisible ? 1 : 0};
                            `}
                        />
                    </Popover>
                    <div className={'flex'}>
                        <Button
                            minimal={true}
                            onClick={onShareClickHandler}
                            icon={IconNames.SHARE}
                        />
                    </div>
                </div>
                <Alert
                    isOpen={isAlertOpen}
                    cancelButtonText={'Cancel'}
                    confirmButtonText={'Delete'}
                    icon={'trash'}
                    onCancel={onAlertCancelHandler}
                    onConfirm={onDeleteButtonHandler}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true}
                    intent={Intent.DANGER}>
                    <H4> Delete list </H4>
                    <p className={'text-base'}>
                        Are you sure you want to delete list <strong> {shareableList.name} </strong>
                        ?
                    </p>
                </Alert>
            </Card>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        error: state.shareableListsPageState.error,
        deletingListId: state.shareableListsPageState.deletingListId
    };
};

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
//   return {
//     deleteItemSLAsyncHandler: (itemId: string) => dispatch(AsyncOperations.deleteListByIdAsync(itemId))
//   }
// }

export default connect(mapStateToProps, null)(ShareableListCard);
